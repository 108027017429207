@import 'vars';

.lit-radios {
	height: auto;
}

.lit-radio {
	margin-right: $space;
	display: inline-block;
	user-select: none;

	&:last-of-type {
		margin-right: 0;
	}

	&__field {
		opacity: 0;
		position: absolute;
	}

	$circleSize: 20px;

	&__label {
		position: relative;
		padding-left: $circleSize + $space-xsm;
		cursor: pointer;

		&:before,
		&:after {
			content: '';
			display: inline-block;
			width: $circleSize;
			height: $circleSize;
			border-radius: 50%;
			background-color: $white;
			position: absolute;
			left: 0;
			top: 3px;
		}

		&:before {
		}

		&:after {
			background-color: $black;
			transform: scale(0.35);
			opacity: 0;
			transition: transform 0.25s, opacity 0.25s;
		}

		@include transitions {
			&:hover {
				&:after {
					opacity: 0.5;
				}
			}
		}

		&:active {
			&:before {
				background-color: $mediumGray;
			}
		}
	}

	.lit-radio__field:checked + .lit-radio__label {
		&:after {
			transform: scale(0.5);
			opacity: 1;
		}
	}
}

.lit-radio__field:focus-visible {
	background: rgb(37, 67, 87);
	color: rgb(169, 180, 12);
}
